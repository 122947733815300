/******************************************************************
Author: Michael Wolf
Modified verison of Contact page

Stylesheet: Sponsor Us Page Stylesheet
******************************************************************/

.sponsor-us-page {
  .hero {
    background-image: url("images/canyons-and-sponsors.jpg");
    background-repeat: no-repeat;
    background-position: center 65%;
    background-size: cover;
  }
  
  text-align: center;
  padding-bottom: 30px;

  h4 {
    font-size: 24px;
  }

  .separator {
    height: $spacing-unit / 2;
  }

  h6 {
    font-size: 18px;
    margin: 0;
    margin-top: 24px;
  }
  p {
    margin: 0;
  }
  a {
    font-size: 16px;
    text-decoration: none;
  
  }

  .cta {
    text-align: center;
    padding: 35px 0px 5px 0px;
    margin: 0 auto;
    max-width: 480px;

    h6 {
      font-size: 24px;
      margin: 0;
    }

    p {
      width: 100%;
      margin: 10px auto 20px auto;
    }
  }
  
  .sponsor_us_wrapper {
    max-width: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    .left-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    .button-hollow {
      margin: 0px 0px 0px 30px;
    }

    @media (max-width: 550px) {
      .left-container {
        flex-direction: column;
      }
      .button-hollow {
        margin: 15px 0px 0px 0px;
      }

    }

    @extend .wrap;
  }


}
