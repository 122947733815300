/******************************************************************
Author: Connor Krupp

Stylesheet: Front Page Stylesheet
******************************************************************/

.about-page {
  .hero {
    background-image: url("images/rover-on-ridge.jpg");
    background-position: center 20%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .row-container {

    padding: 10px;

    &.one {
        @media (max-width: 720px) {
          .text-content {
            display: table-footer-group;
          }
          .block {
            margin: 30px auto;
          }
        }
    }

    &.two {
      background-color: #ecf0f1;
      .text-content {
        padding-right: 0;
        padding-left: 60px;
      }
    }

    &.three {
      background-color: #bdc3c7;
    }

    .wrap {
      display: table;
      max-width: 800px;
    }

    .row-content {
      display:table-cell;
      vertical-align: middle;
      text-align: left;
    }

    .text-content {
      padding: 40px 0;
      padding-right: 60px;
    }

    .block {
      width: 320px;
      height: 200px;
      background-color: #2c3e50;
    }

    .info-section {
      width: 50%;
      text-align: center;
      float: left;
      padding: 30px 0;

      h4 {
        font-size: 1.1em;
        font-weight: 700;
      }
      .info-content {
        padding: 10px 30px;
      }
      .info-block {
        margin: 0 auto;
        width: 160px;
        height: 120px;
        background-color: #2c3e50;
        background-image: url('images/160x120.png');
        background-size: cover;
      }

      &-full {
        @extend .info-section;
        width: 100%;
      }
      
      //align subrow content vertically
      @media (max-width: 620px) {
        width: 100%;
      }
    }

    .subrow {
      @extend .info-section;

      text-align: center;
      float: left;
      width: 50%;

      padding: 0px;
      margin: 0px;
  
      &-2 {
        @extend .subrow;
        width: 100%;
      }
  
      &-4 {
        @extend .subrow;
        width: 50%;
      }
  
      //align subrows vertically (but keep their content horizontal)
      @media (max-width: 1280px) {
        width: 100%;
      }

      &-3-left {
        @extend .subrow;
        width: 67%;
        //align subrows vertically (but keep their content horizontal)
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
  
      &-3-right {
        @extend .subrow;
        width: 33%;
        //align subrows vertically (but keep their content horizontal)
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }

  .info-row-container {
    .wrap {
      max-width: $content-width + 100;
    }
  }

  .row-electrical {
    width: 50%;
  }
  
}
