/******************************************************************
Author: Michael Wolf
Modified verison of Contact page

Stylesheet: Join Page Stylesheet
******************************************************************/

.join-page {
  .hero {
    background-image: url("images/full-send-peace.jpg");
    background-repeat: no-repeat;
    background-position: center 60%;
    background-size: cover;
  }
  
  text-align: center;
  padding-bottom: 30px;

  h4 {
    font-size: 24px;
  }
  h6 {
    font-size: 18px;
    margin: 0;
    margin-top: 24px;
  }
  p {
    margin: 0;
  }
  a {
    font-size: 16px;
    text-decoration: none;
  
  }
//style from https://www.sanwebe.com/2014/08/css-html-forms-designs

.form-style-5{
    max-width: 500px;
    padding: 10px 15px;
    background: #f4f7f8;
    margin: 10px auto;
    padding: 20px;
    background: #f4f7f8;
    border-radius: 8px;
    font-family: Georgia, "Times New Roman", Times, serif;
}
.form-style-5 fieldset{
    border: none;
}
.form-style-5 legend {
    font-size: 1.4em;
    margin-bottom: 15px;
}
.form-style-5 label {
    display: block;
    margin-bottom: 8px;
}
.form-style-5 input[type="text"],
.form-style-5 input[type="date"],
.form-style-5 input[type="datetime"],
.form-style-5 input[type="email"],
.form-style-5 input[type="number"],
.form-style-5 input[type="search"],
.form-style-5 input[type="time"],
.form-style-5 input[type="url"],
.form-style-5 textarea,
.form-style-5 select {
    font-family: Georgia, "Times New Roman", Times, serif;
    background: rgba(255,255,255,.1);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin: 0;
    outline: 0;
    padding: 7px;
    width: 100%;
    box-sizing: border-box; 
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box; 
    background-color: #e8eeef;
    color:#606970; //#8a97a0
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    margin-bottom: 30px;    
}
input::placeholder, textarea::placeholder {
    color: #8a97a0;
}

.form-style-5 textarea {
    height: 90px;
    resize: vertical;
}

.form-style-5 input[type="text"]:focus,
.form-style-5 input[type="date"]:focus,
.form-style-5 input[type="datetime"]:focus,
.form-style-5 input[type="email"]:focus,
.form-style-5 input[type="number"]:focus,
.form-style-5 input[type="search"]:focus,
.form-style-5 input[type="time"]:focus,
.form-style-5 input[type="url"]:focus,
.form-style-5 textarea:focus,
.form-style-5 select:focus{
    background: #d2d9dd;
}
.form-style-5 select{
    -webkit-appearance: menulist-button;
    height:35px;
}
.form-style-5 .number {
    background: #1abc9c;
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255,255,255,0.2);
    border-radius: 15px 15px 15px 0px;
}

.form-style-5 input[type="submit"],
.form-style-5 input[type="button"]
{
    position: relative;
    display: block;
    padding: 19px 39px 18px 39px;
    color: #FFF;
    margin: 0 auto;
    background: #1abc9c;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    width: 100%;
    border: 1px solid #16a085;
    border-width: 1px 1px 3px;
    margin-bottom: 10px;
}
.form-style-5 input[type="submit"]:hover,
.form-style-5 input[type="button"]:hover
{
    background: #109177;
}

#name {

    margin-right: 4px;
    @media(max-width: 460px){
        margin-left: 0px;
    }
}
#email {
    margin-left: 4px;
    @media(max-width: 460px){
        margin-left: 0px;
    }
}
#dual_input_container {
    display: flex;
    justify-content: space-between;
    @media(max-width: 460px) {
        display: block;
        justify-content: center;

    }
}

  //Alternative form styling tool: http://livetools.uiparade.com/form-builder.html

}
