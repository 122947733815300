/******************************************************************
Author: Connor Krupp

Stylesheet: Contact Page Stylesheet
******************************************************************/

.contact-page {
  .hero {
    background-image: url("images/festifall2022.jpg");
    background-repeat: no-repeat;
    background-position: center 56%;
    background-size: cover;
  }
  .contact-info {
    text-align: center;
    padding-bottom: 30px;

    h4 {
      font-size: 24px;
    }
    h6 {
      font-size: 18px;
      margin: 0;
      margin-top: 24px;
    }
    p {
      margin: 0;
    }
    a {
      font-size: 16px;
      text-decoration: none;
    }
  }
}
