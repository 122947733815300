@charset "utf-8";



// Our variables
$base-font-family: "Lato", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    1200px;

//on-palm control the number of pixels at which the navigation list collapses into the hamburger button
$on-palm:          910px;
$on-laptop:        910px;



@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "variables",
        "base",
        "header",
        "home",
        "contact",
        "about",
        "layout",
        "syntax-highlighting",
        "join",
        "rovers",
        "sponsors",
        "sponsor_us"
;
