/*********************
COLORS
*********************/

$alert-yellow:      #ebe16f;
$alert-red:         #fbe3e4;
$alert-green:       #e6efc2;
$alert-blue:        #d5edf8;

$black:             #323944;
$white:             #fff;

$light-gray:        #f8f9fa;
$gray:              #eaedf2;
$text-color:        #5c6b80;
$meta-gray:         #9fa6b4;

$tan:               #eceee5;

$green:             #7dd667;

$blue:              #2980b9;

$border-color:      #ccc;
$disabled-gray:     #cfcfcf;

