/******************************************************************
Author: Michael Wolf
Modified verison of Contact page

Stylesheet: Sponsors Page Stylesheet
******************************************************************/

.sponsors-page {
  .hero {
    background-image: url("images/circuit-boards.jpg");
    background-repeat: no-repeat;
    background-position: center 45%;
    background-size: cover;
  }
  
  text-align: center;
  padding-bottom: 30px;

  h4 {
    font-size: 24px;
  }

  .separator {
    height: $spacing-unit / 2;
  }

  h6 {
    font-size: 18px;
    margin: 0;
    margin-top: 24px;
  }
  p {
    margin: 0;
  }
  a {
    font-size: 16px;
    text-decoration: none;
  
  }

  .cta {
    text-align: center;
    padding: $spacing-unit/2 0px 5px 0px;
    margin: 0 auto;
    max-width: 480px;

    h6 {
      font-size: 24px;
      margin: 0;
    }

    p {
      width: 100%;
      margin: 10px auto 20px auto;
    }
  }
}
